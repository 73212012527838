import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Draw } from 'app/components/Common/Draw'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

const AUTOPLAY_TIMER = 5000

export interface Props {
  slides: ImageProps[]
  slidesMobile?: ImageProps[]
  imageSmall?: ImageProps
  variant?: string
  pretitle?: string
  title?: string
  description?: string
  button?: ButtonProps
}

export const BlockSlider = memo(function BlockSLider({
  slides,
  imageSmall,
  pretitle,
  title,
  description,
  button,
}: Props) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderMain, instanceRefMain] = useKeenSlider({
    loop: true,
    drag: false,
    initial: 0,
    slides: { perView: 2, spacing: 36 },
    defaultAnimation: {
      duration: 1500,
    },
    breakpoints: {
      '(max-width: 991px)': {
        slides: { perView: 1.2, spacing: 5, origin: 'center' },
        loop: false,
        drag: true,
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      let index = s.track.details.rel
    },
  })

  return (
    <Container>
      <StyledDraw variant="flower-02" />
      <Top>
        {pretitle ? (
          <FadeInUp>
            <Pretitle dangerouslySetInnerHTML={{ __html: pretitle }} />
          </FadeInUp>
        ) : null}
        {title ? <Title text={title} /> : null}
      </Top>

      <MainSlider>
        <FadeInUp>
          <SliderImage ref={sliderMain} className="keen-slider">
            {slides.map((item, index) => (
              <SlideImage key={index} className="keen-slider__slide">
                <Image {...item} />
              </SlideImage>
            ))}
          </SliderImage>
        </FadeInUp>
        <FadeIn>
          <Arrow
            className="arrow-left"
            onClick={(e) => {
              instanceRefMain.current?.prev()
            }}
          />
          <Arrow
            className="arrow-right"
            direction="R"
            onClick={(e) => {
              instanceRefMain.current?.next()
            }}
          />
        </FadeIn>

        {imageSmall ? (
          <ImageSmall>
            <FadeInUp>
              <Image {...imageSmall} />
            </FadeInUp>
          </ImageSmall>
        ) : null}
      </MainSlider>

      <Text>
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {button ? (
          <FadeInUp>
            <StyledButton {...button} />
          </FadeInUp>
        ) : null}
      </Text>
    </Container>
  )
})

const Container = styled.section`
  padding: 6.66vw;
  position: relative;
  width: 100%;

  @media (max-width: 991px) {
    padding: 30px;
  }
`
const StyledDraw = styled(Draw)`
  top: 35%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  img {
    width: 40vw;
  }
`

const Top = styled.div``

const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const Title = styled(AnimatedTitle)`
  font-size: 4vw;
  margin: 3vw 0 0 0;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 20px 0;
  }
`

const MainSlider = styled.div`
  width: 100%;
  margin-top: 5vw;
  position: relative;

  .arrow-left {
    top: 50%;
    left: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translate3d(-50%, -50%, 0);
  }
  .arrow-right {
    top: 50%;
    right: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translate3d(50%, -50%, 0);
  }

  @media (max-width: 991px) {
    margin-top: 20px;
    width: calc(100% + 65px);
    margin-left: -35px;

    .arrow-left,
    .arrow-right {
      display: none;
    }
  }
`

const SliderImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`
const SlideImage = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`

const ImageSmall = styled.div`
  width: 17vw;
  height: auto;
  position: absolute;
  right: 4.5%;
  top: 85%;

  img {
    width: 100%;
    height: auto;
    border: 15px solid ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  @media (max-width: 991px) {
    display: none;
  }
`

const Text = styled.div`
  padding: 0 2.7vw;
  position: relative;
  margin-top: 5vw;
  width: 75%;
  max-width: 800px;

  &::before {
    top: 0;
    left: 0;
    content: ' ';
    width: 1px;
    height: 100%;
    position: absolute;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    display: block;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: auto;
    padding: 0 30px;
    margin-top: 30px;
  }
`
const Description = styled.div``

const StyledButton = styled(Button)`
  margin-top: 2.5vw;

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`
